/* You can add global styles to this file, and also import other style files */

html, body {
	height: auto;
	overflow-x: hidden;
	overflow-y: auto;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}
